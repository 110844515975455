<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					숙소 예약내역
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="content">
				<div class="box">
					<!-- tab -->
					<div class="tab">
						<a href="#">방문 전</a>
						<a href="#">방문 후</a>
						<a href="#" class="active">예약 취소</a>
					</div>
					<!-- 예약 내역-->
					<div class="reservation">
						<!-- 예약 아이템 -->
						<div class="reservation-item">
							<!-- [D] 예약 상태에 따라 클래스 변경 ing, end, cancel-->
							<div class="reservation__status cancel">
								<strong>2021.11.12 (수)</strong>
								<span class="reservation__badge">
									취소완료
								</span>
							</div>
							<div class="reservation__number">
								<div class="flex">
									<span>
										예약번호 R1234
									</span>
									<a href="#">
										상세내역
									</a>
								</div>
							</div>
							<div class="reservation__info">
								<h3 class="reservation__title">종로 레몬트리</h3>
								<div class="flex">
									<div class="top__type">
										<span class="top__badge grey1"> 숙박 </span>
										디럭스
									</div>
									<div class="detail__sns">
										<button type="button">
											<i class="icon icon-location--small">위치</i>
											위치
										</button>
										<a href="tel:010">
											<i class="icon icon-tel"></i> 전화
										</a>
									</div>
								</div>
								<ul class="reservation__time">
									<li><span>체크인</span> 2021.09.08(수) 15:00</li>
									<li><span>체크아웃</span> 2021.09.10(수) 15:00</li>
								</ul>
							</div>
						</div>
						<div class="reservation-item">
							<!-- [D] 예약 상태에 따라 클래스 변경 ing, end, cancel-->
							<div class="reservation__status cancel">
								<strong>2021.11.12 (수)</strong>
								<span class="reservation__badge">
									취소완료
								</span>
							</div>
							<div class="reservation__number">
								<div class="flex">
									<span>
										예약번호 R1234
									</span>
									<a href="#">
										상세내역
									</a>
								</div>
							</div>
							<div class="reservation__info">
								<h3 class="reservation__title">종로 레몬트리</h3>
								<div class="flex">
									<div class="top__type">
										<span class="top__badge grey1"> 숙박 </span>
										디럭스
									</div>
									<div class="detail__sns">
										<button type="button">
											<i class="icon icon-location--small">위치</i>
											위치
										</button>
										<a href="tel:010">
											<i class="icon icon-tel"></i> 전화
										</a>
									</div>
								</div>
								<ul class="reservation__time">
									<li><span>체크인</span> 2021.09.08(수) 15:00</li>
									<li><span>체크아웃</span> 2021.09.10(수) 15:00</li>
								</ul>
							</div>
						</div>
						<div class="reservation-item">
							<!-- [D] 예약 상태에 따라 클래스 변경 ing, end, cancel-->
							<div class="reservation__status cancel">
								<strong>2021.11.12 (수)</strong>
								<span class="reservation__badge">
									취소완료
								</span>
							</div>
							<div class="reservation__number">
								<div class="flex">
									<span>
										예약번호 R1234
									</span>
									<a href="#">
										상세내역
									</a>
								</div>
							</div>
							<div class="reservation__info">
								<h3 class="reservation__title">종로 레몬트리</h3>
								<div class="flex">
									<div class="top__type">
										<span class="top__badge grey1"> 숙박 </span>
										디럭스
									</div>
									<div class="detail__sns">
										<button type="button">
											<i class="icon icon-location--small">위치</i>
											위치
										</button>
										<a href="tel:010">
											<i class="icon icon-tel"></i> 전화
										</a>
									</div>
								</div>
								<ul class="reservation__time">
									<li><span>체크인</span> 2021.09.08(수) 15:00</li>
									<li><span>체크아웃</span> 2021.09.10(수) 15:00</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>